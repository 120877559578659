import { observable, action } from 'mobx';

import { ValidationResult } from '../services/validator';
import terminalLoginValidator from './validators/terminalLoginValidator';
import { services } from '../services/index';
import terminalAuthStore from './terminalAuthStore';

class TerminalLoginStore {
    @observable isBusy = false;
    @observable username = '';
    @observable password = '';
    @observable.ref validationResult = new ValidationResult();

    @action submitLogin = (e, autoLogin) => {
        if (!autoLogin) {
            e.preventDefault();
        }

        this.validationResult = terminalLoginValidator.validate({
            username: this.username,
            password: this.password,
        });

        if (this.validationResult.pass()) {
            this.isBusy = true;

            services.api
                .TerminalLogin(this.username, this.password)
                .then((credentials) => {
                    const accessToken = credentials['access_token'];
                    services.api.AccessTokenStorage.setToken(accessToken);
                    terminalAuthStore.authed = true;
                    localStorage.setItem('terminalUserName', this.username);
                })
                .catch((response) => {
                    this.validationResult = new ValidationResult().addError(
                        'password',
                        response.message
                    );
                })
                .then(() => {
                    this.isBusy = false;
                    localStorage.removeItem('username');
                    localStorage.removeItem('password');
                });
        }
    };

    @action updateModel(e) {
        this[e.currentTarget.name] = e.currentTarget.value;
    }
}

const store = new TerminalLoginStore();
export default store;
