import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import { services } from '../../../services';

const base = 'terminal-login';

@inject('terminalLoginStore')
@observer
export default class extends React.Component {
    render() {
        const store = this.props.terminalLoginStore;
        const updateModel = ::store.updateModel;
        const buttonClasses = store.isBusy ? 'button--busy terminal' : '';

        return (
            <div className={`${base}__content`}>
                <center>
                    <div className={`${base}__title`}>
                        <span>food</span>
                        <span className="text--red">{' terminal'}</span>
                    </div>
                    <p className={`${base}__subtitle`}>
                        by {services.getParam('brandName')}
                    </p>
                    <div
                        className={`${base}__sub-heading fs--small-medium lh--125`}
                    >
                        {intl.get('terminal.login.subheading')}
                    </div>
                </center>
                <center>
                    <form
                        className={`${base}__form`}
                        onSubmit={::store.submitLogin}
                    >
                        <div className="mbm">
                            <input
                                type="text"
                                className={`${base}__input input width--100`}
                                placeholder="Username"
                                name="username"
                                value={store.username}
                                onChange={updateModel}
                            />
                        </div>
                        <div className="mbm">
                            <input
                                type="password"
                                className={`${base}__input input width--100`}
                                placeholder="Password"
                                name="password"
                                value={store.password}
                                onChange={updateModel}
                            />
                        </div>
                        <div
                            className={`${base}__error-wrapper display--none-if-empty pts pbs text--red mbm`}
                        >
                            {store.validationResult.getFirstError()}
                        </div>
                        <button
                            className={`button button--success width--100 ${buttonClasses}`}
                        >
                            GO
                        </button>
                    </form>
                </center>
            </div>
        );
    }
}
