import React, { Component } from 'react';
import config from '../../configs/parameters';
import brandLogo from './brandLogo/MasterBrandLogo';
import ButtonUI from './loginPopup/ButtonUI';

const base = 'offline-page';
export class OfflinePage extends Component {
    handleClickRefresh = () => {
        if (navigator.onLine) {
            window.location.reload();
        }
    };

    render() {
        return (
            <div className={base}>
                <div className="container text--center">
                    <brandLogo.PurpleLogo
                        className={`${base}__onboarding-logo`}
                    />
                    <p className={`${base}__text fs--medium-large`}>
                        You might have lost connection to the internet. Try
                        checking your internet connection.
                    </p>
                    {navigator.onLine && (
                        <div className={`${base}__button-container`}>
                            <ButtonUI
                                onClick={this.handleClickRefresh}
                                customClasses="button--success"
                                text="Reload page"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    getMetaTags() {
        return [
            { charset: 'utf-8' },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
            { name: 'google-signin-client_id', content: config.googleClientId },
            { name: 'apple-itunes-app', content: 'app-id=1030383844' },
            { name: 'msapplication-TileColor', content: '#ffffff' },
            {
                name: 'msapplication-TileImage',
                content: '/ms-icon-144x144.png',
            },
            { name: 'apple-mobile-web-app-title', content: config.brandName },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
            {
                name: 'apple-mobile-web-app-status-bar-style',
                content: '#ffffff',
            },
            { name: 'theme-color', content: '#ffffff' },
        ];
    }
}
