import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
const base = 'terminal-lucky-wheel';
const ONE_SECOND = 1000; //miliseconds in one second
const WHEEL_ROTATION_TIME_DURATION = 2 * ONE_SECOND;
@inject('terminalMenuStore')
@observer
export default class LuckyWheel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            radiusInPixels: 225, // PIXELS
            angle: 0, // RADIANS
            top: null, // INDEX
            netInRadians: null, // RADIANS
            result: null, // INDEX
            easeOutInSecs: 0, // SECONDS
            rotateInDegrees: 0, // DEGREES
            spinning: false,
            playBtnClicked: false,
        };
    }

    componentDidMount() {
        let formId = 'cSyvhBe6';
        if (this.props.terminalMenuStore.getLanguage() == 'en') {
            formId = 'HPlEvs1w';  
        }
        const popup = createPopup(formId, { size: 70 });
        popup.open();
        setTimeout(() => {
            console.log('Closing typeform');
            if (popup) {
                popup.close();
            }
        }, 120000);


        if (this.props.terminalMenuStore.askForName) {
            window.dataLayer.push({ 'event': 'kioskFirstPurchase' })
        }
        // generate canvas wheel on load
        this.renderWheel();
        const smallTimeDuration = 0.2 * ONE_SECOND; //set rotation and other values after some small duration so that rotation of wheel works good
        setTimeout(() => {
            this.setState({
                easeOutInSecs: 40, // SECONDS wheel will rotate for 40s until user hit play button
                rotateInDegrees: 1000, // DEGREES
                spinning: true,
            });
        }, smallTimeDuration);
    }

    topPosition = (num, angle) => {
        // set starting index based on list length
        // works upto 5 skus and 5 null options
        let topSpot = null;
        if (num === 10) {
            topSpot = 2;
        } else if (num === 8) {
            topSpot = 2;
        } else if (num == 6) {
            topSpot = 2;
        } else if (num === 4) {
            topSpot = 2;
        } else if (num == 2) {
            topSpot = 1;
        }

        this.setState({
            top: topSpot - 1,
        });
    };

    getContext = () => document.getElementById('wheel').getContext('2d');
    getCanvas = () => document.getElementById('wheel');

    renderWheel() {
        // determine number/size of sectors that need to created
        const { addonsToShowOnLuckyWheel } = this.props.terminalMenuStore;
        const numOptions = addonsToShowOnLuckyWheel.length * 2;
        const arcSize = (2 * Math.PI) / numOptions;
        this.setState({
            angle: arcSize,
        });

        // get index of starting position of selector
        this.topPosition(numOptions, arcSize);

        //rendering full outer circle of wheel
        const canvas = this.getCanvas();
        const ctx = this.getContext();
        const x = canvas.width / 2;
        const y = canvas.height / 2;
        ctx.beginPath();
        ctx.arc(x, y, this.state.radiusInPixels, 0, 2 * Math.PI, false);
        ctx.lineWidth = 40;
        ctx.strokeStyle = '#f4594f';
        ctx.stroke();

        this.renderCenterCircle();

        // dynamically generate sectors from items
        let angle = 0;
        for (let i = 0; i < numOptions; i++) {
            this.renderSector(
                i + 1,
                addonsToShowOnLuckyWheel[i],
                angle,
                arcSize
            );
            angle += arcSize;
        }
    }

    renderCenterCircle() {
        const canvas = this.getCanvas();
        const ctx = this.getContext();
        const x = canvas.width / 2;
        const y = canvas.height / 2;
        ctx.beginPath();
        ctx.fillStyle = '#f4594f';
        ctx.fillStyle = '#000000';
        ctx.arc(x, y, 10, 0, 2 * Math.PI, false);
        ctx.fill();
    }

    renderSector(index, item, start, arc) {
        // create canvas arc for each item element
        const canvas = this.getCanvas();
        const ctx = this.getContext();
        const x = canvas.width / 2;
        const y = canvas.height / 2;
        const radiusInPixels = this.state.radiusInPixels;
        const startAngle = start;
        const endAngle = start + arc;

        ctx.beginPath();
        ctx.fillStyle = '#fff4cc';
        ctx.moveTo(x, y);
        ctx.arc(x, y, radiusInPixels, startAngle, endAngle, false);
        ctx.lineTo(x, y);
        ctx.lineWidth = 8;
        ctx.strokeStyle = '#f4594f';
        ctx.stroke();
        ctx.fill();
    }

    spin = () => {
        // set random spin degree and ease out time
        // set state variables to initiate animation
        let randomSpin = Math.floor(Math.random() * 900) + 500;

        const { terminalMenuStore } = this.props;
        const { addonsToShowOnLuckyWheel } = terminalMenuStore;
        const numOfAddons = addonsToShowOnLuckyWheel.length * 2;
        let numberOfSectors = 360 / numOfAddons;
        if (numOfAddons === 10) {
            if (Math.floor((randomSpin - 18) / numberOfSectors) % 2 != 0) {
                randomSpin = randomSpin + numberOfSectors;
            }
        } else if (numOfAddons === 8) {
            if (Math.floor(randomSpin / numberOfSectors) % 2 == 0) {
                randomSpin = randomSpin + numberOfSectors;
            }
        } else if (numOfAddons === 6) {
            if (Math.floor((randomSpin - 30) / numberOfSectors) % 2 != 0) {
                randomSpin = randomSpin + numberOfSectors;
            }
        } else if (numOfAddons === 4) {
            if (Math.floor(randomSpin / numberOfSectors) % 2 != 0) {
                randomSpin = randomSpin + numberOfSectors;
            }
        } else if (numOfAddons === 2) {
            if (Math.floor((randomSpin - 90) / numberOfSectors) % 2 != 0) {
                randomSpin = randomSpin + numberOfSectors;
            }
        }

        this.setState({
            rotateInDegrees: randomSpin,
            easeOutInSecs: 2, //wheel will spin for only 2secs
            spinning: true,
            playBtnClicked: true,
        });

        // calcalute result after wheel stops spinning
        setTimeout(() => {
            this.getResult(randomSpin);
            //wheel will spin for 2 seconds
        }, WHEEL_ROTATION_TIME_DURATION);
    };

    getResult = (spin) => {
        const { rewardRedeemCallback, terminalMenuStore } = this.props;
        const {
            setLuckyWheelReward,
            setShowLuckyWheel,
            addonsToShowOnLuckyWheel,
            setShowPopup,
            showingPopup,
        } = terminalMenuStore;

        // find net rotation
        // repeat substraction of inner angle amount from total distance traversed
        // use count as an index to find value of result from state items list
        const { angle, top } = this.state;
        let netRotation = ((spin % 360) * Math.PI) / 180; // RADIANS
        let travel = netRotation;
        let count = top + 1;
        while (travel > 0) {
            travel = travel - (angle * 2);
            count--;
        }
        let result;
        if (count >= 0) {
            result = count;
        } else {
            result = addonsToShowOnLuckyWheel.length + count;
        }
        const reward = addonsToShowOnLuckyWheel[result];

        setLuckyWheelReward(reward);
        if (rewardRedeemCallback) {
            //call the reward redeem endpoint
            rewardRedeemCallback();
        }
        setTimeout(() => {
            setShowLuckyWheel(false);
        }, 1 * 1000);

        // set state variable to display result
        this.setState({
            netInRadians: netRotation,
            result: result,
            spinning: false,
            rotateInDegrees: null,
            playBtnClicked: false,
        });
    };

    render() {
        const { rotateInDegrees, easeOutInSecs, playBtnClicked } = this.state;
        const { addonsToShowOnLuckyWheel, PIE_TRANSLATION_ROTATION_VALUES_FOR_ITEMS, PIE_TRANSLATION_ROTATION_VALUES_FOR_DRINKS, PIE_TRANSLATION_ROTATION_VALUES } =
            this.props.terminalMenuStore;
        const numOfAddons = addonsToShowOnLuckyWheel.length * 2;

        return (
            <>
                <div className={`${base}__lucky_wheel_container`}>
                    <h1 className={`${base}__title`}>
                        {intl.get('terminal.luckyWheel.title')}
                    </h1>

                    <div className={`${base}__wheel_container`}>
                        <div className={`${base}__pointer_triangle`}></div>
                        <canvas
                            id="wheel"
                            width="505"
                            height="505"
                            style={{
                                WebkitTransform: `rotate(${rotateInDegrees}deg)`,
                                WebkitTransition: `-webkit-transform ${easeOutInSecs}s ease-out`,
                            }}
                        />
                        <div
                            className={`${base}__custom_overlapping_wheel`}
                            style={{
                                WebkitTransform: `rotate(${rotateInDegrees}deg)`,
                                WebkitTransition: `-webkit-transform ${easeOutInSecs}s ease-out`,
                            }}
                        >
                            {Array.from(Array(numOfAddons), (_, i) => i + 1).map(
                                (num) => {
                                    return (
                                        <div>
                                            {
                                                num % 2 != 0 ?
                                                    <div
                                                        className={`${base}__custom_pie`}
                                                        style={{
                                                            position: 'absolute',
                                                            right: `${addonsToShowOnLuckyWheel[
                                                                Math.floor(num / 2)
                                                            ].item_type === "Item" ?
                                                                PIE_TRANSLATION_ROTATION_VALUES_FOR_ITEMS[
                                                                    addonsToShowOnLuckyWheel
                                                                        .length * 2
                                                                ][num].xTranslate :
                                                                PIE_TRANSLATION_ROTATION_VALUES_FOR_DRINKS[
                                                                    addonsToShowOnLuckyWheel
                                                                        .length * 2
                                                                ][num].xTranslate
                                                                }px`,
                                                            top: `${addonsToShowOnLuckyWheel[
                                                                Math.floor(num / 2)
                                                            ].item_type === "Item" ?
                                                                PIE_TRANSLATION_ROTATION_VALUES_FOR_ITEMS[
                                                                    addonsToShowOnLuckyWheel
                                                                        .length * 2
                                                                ][num].yTranslate :
                                                                PIE_TRANSLATION_ROTATION_VALUES_FOR_DRINKS[
                                                                    addonsToShowOnLuckyWheel
                                                                        .length * 2
                                                                ][num].yTranslate
                                                                }px`,
                                                            transform: `rotate(${addonsToShowOnLuckyWheel[
                                                                Math.floor(num / 2)
                                                            ].item_type === "Item" ?
                                                                PIE_TRANSLATION_ROTATION_VALUES_FOR_ITEMS[
                                                                    addonsToShowOnLuckyWheel
                                                                        .length * 2
                                                                ][num].rotate :
                                                                PIE_TRANSLATION_ROTATION_VALUES_FOR_DRINKS[
                                                                    addonsToShowOnLuckyWheel
                                                                        .length * 2
                                                                ][num].rotate
                                                                }deg)`,
                                                        }}
                                                    >
                                                        <img
                                                            className={`${base}__img`}
                                                            src={
                                                                addonsToShowOnLuckyWheel[
                                                                    Math.floor(num / 2)
                                                                ].digital_menu_image
                                                                    ? addonsToShowOnLuckyWheel[
                                                                        Math.floor(num / 2)
                                                                    ].digital_menu_image
                                                                    : addonsToShowOnLuckyWheel[
                                                                        Math.floor(num / 2)
                                                                    ].image
                                                            }
                                                        />
                                                    </div>
                                                    :
                                                    <div
                                                        className={`${base}__custom_pie`}
                                                        style={{
                                                            position: 'absolute',
                                                            right: `${PIE_TRANSLATION_ROTATION_VALUES[
                                                                addonsToShowOnLuckyWheel
                                                                    .length * 2
                                                            ][num].xTranslate
                                                                }px`,
                                                            top: `${PIE_TRANSLATION_ROTATION_VALUES[
                                                                addonsToShowOnLuckyWheel
                                                                    .length * 2
                                                            ][num].yTranslate
                                                                }px`,
                                                            transform: `rotate(${PIE_TRANSLATION_ROTATION_VALUES[
                                                                addonsToShowOnLuckyWheel
                                                                    .length * 2
                                                            ][num].rotate
                                                                }deg)`,
                                                        }}
                                                    >
                                                        <img
                                                            className={`${base}__img`}
                                                            src={
                                                                "/fa-solid_bomb.svg"
                                                            }
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    );
                                }
                            )}
                        </div>
                        {numOfAddons > 2 && (
                            <div className={`${base}__center_circle`}></div>
                        )}
                    </div>

                    <button
                        type="button"
                        className={`${base}__play_btn`}
                        onClick={this.spin}
                        disabled={playBtnClicked}
                    >
                        {intl.get('terminal.luckyWheel.actionButton')}
                    </button>
                </div>
            </>
        );
    }
}
