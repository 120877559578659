import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import { services } from '../../../services/index';
import { CircleLoader } from '../../core/Loading';
import CopyToClipboard from 'react-copy-to-clipboard';
import { RESOURCE } from '../../../services/types';
import { Link } from 'react-router-dom';
import template from 'lodash/template';

const { clevertap } = window;
const base = 'rewards';

@inject('userStore', 'rewardsStore', 'orderHistoryStore')
@observer
export default class extends React.Component {
    state = { copied: false };

    onCopy = () => {
        this.setState({ copied: true });
    };

    sendTracking(platform) {
        clevertap.event.push('Clicked on Send Invites', {
            Platform: platform,
            'Accessed from': 'Free meals',
        });
    }

    componentDidMount() {
        const { rewardsStore, orderHistoryStore } = this.props;
        rewardsStore.getRules();
        orderHistoryStore.loadOrderHistory();
    }

    renderShareButton() {
        const { rewardsStore, orderHistoryStore } = this.props;
        const { orderHistory } = orderHistoryStore;
        const { upcomingOrders } = orderHistoryStore;

        if (
            rewardsStore.referralLink &&
            !(
                orderHistory === RESOURCE.Loading ||
                upcomingOrders === RESOURCE.Loading
            )
        ) {
            if (!rewardsStore.canRefer) {
                return (
                    <div className="mtm">
                        <p>{intl.get('rewards.noOrdersPlaced')}</p>
                        <div className="text--center">
                            <Link to="/menu">
                                <button
                                    className={`${base}__empty-cta button mtm button--success width--100`}
                                >
                                    {intl.get('rewards.shareBox.menu')}
                                </button>
                            </Link>
                        </div>
                    </div>
                );
            } else {
                const buttonClasses = this.state.copied
                    ? 'display--inline-block mtm button button--medium button--disabled'
                    : 'display--inline-block mtm button button--medium button--info';
                const appId = services.getParam('facebookAppId');
                const shareText = encodeURI(
                    rewardsStore.shareText + '\n' + rewardsStore.referralLink
                );
                // checking if user is on iOS, in that case the separator for sms deeplink needs to be "&"
                const separator = /iPhone|iPad|iPod/i.test(navigator.userAgent)
                    ? '&'
                    : '?';
                return (
                    <div>
                        <input
                            className="display--inline-block input mtm dmk-button-height"
                            readOnly
                            style={{ verticalAlign: 'top', width: '68.5%' }}
                            value={rewardsStore.referralLink}
                        />
                        <CopyToClipboard
                            onCopy={this.onCopy}
                            text={rewardsStore.referralLink}
                        >
                            <button
                                onClick={() => this.sendTracking('Clipboard')}
                                style={{
                                    maxHeight: '38px',
                                    minHeight: '38px',
                                    width: '31.5%',
                                }}
                                className={buttonClasses}
                            >
                                {this.state.copied ? (
                                    <span>
                                        {intl.get('rewards.shareBox.copied')}
                                    </span>
                                ) : (
                                    <span>
                                        {intl.get('rewards.shareBox.copy')}
                                    </span>
                                )}
                            </button>
                        </CopyToClipboard>
                        <div
                            className={` ${base}__share-container display--flex `}
                        >
                            <button
                                className="mtm button button--medium button--facebook"
                                onClick={() => {
                                    this.share();
                                }}
                            >
                                <span className="hide--if-small show--if-large">
                                    {intl.get(
                                        'rewards.shareBox.facebook.large'
                                    )}
                                </span>
                                <span className="show--if-small hide--if-large">
                                    {intl.get(
                                        'rewards.shareBox.facebook.small'
                                    )}
                                </span>
                            </button>
                            <a
                                rel="nofollow noopener noreferrer"
                                className="link--no-underline"
                                onClick={() => this.sendTracking('WhatsApp')}
                                target="_blank"
                                href={`https://api.whatsapp.com/send?text=${shareText}`}
                            >
                                <button className="mtm button button--medium button--whatsapp width--100">
                                    <span className="hide--if-small show--if-large">
                                        {intl.get(
                                            'rewards.shareBox.whatsapp.large'
                                        )}
                                    </span>
                                    <span className="show--if-small hide--if-large">
                                        {intl.get(
                                            'rewards.shareBox.whatsapp.small'
                                        )}
                                    </span>
                                </button>
                            </a>
                            <a
                                className="link--no-underline hide--if-small show--if-large"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                onClick={() => this.sendTracking('Messenger')}
                                href={` http://www.facebook.com/dialog/send?app_id=${appId}&link=${rewardsStore.referralLink}&redirect_uri=${rewardsStore.referralLink}`}
                            >
                                <button className="mtm button button--medium button--messenger width--100">
                                    <span className="hide--if-small show--if-large">
                                        {intl.get(
                                            'rewards.shareBox.messenger.large'
                                        )}
                                    </span>
                                    <span className="show--if-small hide--if-large">
                                        {intl.get(
                                            'rewards.shareBox.whatsapp.small'
                                        )}
                                    </span>
                                </button>
                            </a>
                            <a
                                className="link--no-underline show--if-small hide--if-large"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                onClick={() => this.sendTracking('SMS')}
                                href={`sms:${separator}body=${shareText}`}
                            >
                                <button className="mtm button button--medium button--sms width--100">
                                    <span className="hide--if-small show--if-large">
                                        {intl.get('rewards.shareBox.sms.large')}
                                    </span>
                                    <span className="show--if-small hide--if-large">
                                        {intl.get('rewards.shareBox.sms.small')}
                                    </span>
                                </button>
                            </a>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div className="mtm">
                    <center>
                        <CircleLoader />
                    </center>
                </div>
            );
        }
    }

    share() {
        const { rewardsStore } = this.props;
        const shareText = rewardsStore.shareText;

        window.FB.ui(
            {
                method: 'share',
                display: 'popup',
                mobile_iframe: false,
                href: this.props.rewardsStore.referralLink,
                quote: shareText,
            },
            function (response) {}
        );

        this.sendTracking('Facebook');
    }

    handleRewardDisplay = (reward) => {
        const currency = services.getParam('currency');
        return currency + reward;
    };

    renderRules() {
        const { rewardsStore } = this.props;
        if (
            rewardsStore.rules === RESOURCE.Loading ||
            rewardsStore.rules === RESOURCE.Initial ||
            !rewardsStore.user
        ) {
            return null;
        }
        return (
            <div className={`${base}__box-header mbm lh--125`}>
                {intl.get('rewards.shareBox.titleShare')}
            </div>
        );
    }

    renderRulesDetail() {
        const { rewardsStore } = this.props;

        if (
            rewardsStore.rules === RESOURCE.Loading ||
            rewardsStore.rules === RESOURCE.Initial ||
            !rewardsStore.user
        ) {
            return null;
        }
        return (
            <Fragment>
                <p className={`${base}__details-text`}>
                    {template(intl.get('rewards.shareBox.textLine1'))({
                        referredAmount: this.handleRewardDisplay(
                            rewardsStore.rules.data.referred_user_amount
                        ),
                        brandName: services.getParam('brandName'),
                    })}
                </p>
                <br />
                <p className={`${base}__details-text`}>
                    {rewardsStore.rules.data.referring_text}
                </p>
            </Fragment>
        );
    }

    render() {
        const { customClasses } = this.props;
        return (
            <div
                className={`${base}__box ${customClasses || 'border--rounded'}`}
            >
                {this.renderRules()}
                <div className={`${base}__box-body`}>
                    {this.renderRulesDetail()}
                    {this.renderShareButton()}
                </div>
            </div>
        );
    }
}
