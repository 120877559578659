import React from 'react';

import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'terminal-payment-option';
@inject('terminalCartStore')
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    handlePaymentOption = (type) => {
        const { showOnMobile, terminalMenuStore } = this.props;
        if (type == 'ewallet') {
            if (terminalMenuStore.backBtn) {
                console.log('show popup qr code', terminalMenuStore.showPopup);
                terminalMenuStore.paymentType = 'Kiosk_Ewallet';
                terminalMenuStore.qrCodeText = 'Error';
                terminalMenuStore.backBtn = false;
                terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_QRCODE);
            } else {
                terminalMenuStore.paymentType = 'Kiosk_Ewallet';
                terminalMenuStore.submitQRPayment(showOnMobile, type);
            }
        } else if (type == 'card') {
            if (terminalMenuStore.backBtn) {
                terminalMenuStore.paymentType = 'Card';
                terminalMenuStore.getPendingOrderTransaction(() => {
                    console.log('show popup tap card', terminalMenuStore.showPopup);
                    terminalMenuStore.backBtn = false;
                    terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_TAP_CARD);
                });
            } else {
                terminalMenuStore.paymentType = 'Card';
                terminalMenuStore.submitQRPayment(showOnMobile, type);
            }
        } else {
            const { eligibleForLuckyWheelAfterTableNum, askForName } =
                terminalMenuStore;
            const isNewUser = askForName;
            const outletName = localStorage.getItem('terminalUserName') || null;
            const deviceId = terminalMenuStore.KIOSK_RANDOM_ID || null;
            terminalMenuStore.paymentType = 'Pos';
            if (terminalMenuStore.backBtn) {
                terminalMenuStore.cashReorder();
            } else {
                terminalMenuStore.confirmOrder(
                    showOnMobile,
                    isNewUser,
                    eligibleForLuckyWheelAfterTableNum,
                    outletName,
                    deviceId
                );
            }
        }
    };

    showCardOption = () => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        if (!terminalMenuStore.cardReaderStatus) {
            return false;
        }
        if (terminalCartStore.totalPrice - 2 < 0) {
            return false;
        } 
        return true;
    }

    componentDidMount = () => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.paymentType = 'Pos';
    }

    render() {
        const { terminalMenuStore } = this.props;
        return (
            <div className={`${this.showCardOption() ? `${base} ${base}--large` : `${base}` }`}>
                <div style={{ marginTop: '20px' }} className={`${base}__title`}>
                    <p> {intl.get('terminal.qrPopup.payment_title')}</p>
                </div>
                <div style={{ display: 'flex', marginTop: '24px', justifyContent: 'center' }}>
                    <div
                        onClick={() => this.handlePaymentOption('ewallet')}
                        className={`${base} payment-card`}
                        style={{
                            width: this.showCardOption() ? '33%' : '50%',
                            border: '3px solid #00C6BF',
                            borderRadius: '8px',
                        }}
                    >
                        <div style={{ padding: '16px', height: '100%' }}>
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src="https://image.popcontent.link/ewallet.jpg"
                            />
                        </div>

                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: '24px',
                                color: '#00C6BF',
                                paddingBottom: '19px',
                            }}
                        >
                            {intl.get('terminal.qrPopup.ewallet_title')}
                        </div>
                    </div>

                    { this.showCardOption() ? (<div
                        onClick={() => this.handlePaymentOption('card')}
                        className={`${base} payment-card`}
                        style={{
                            width: '33%',
                            border: '3px solid #7056B5',
                            borderRadius: '8px',
                        }}
                    >
                        <div style={{ padding: '16px', height: '100%' }}>
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src="https://image.popcontent.link/card.jpg"
                            />
                        </div>

                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: '24px',
                                color: '#7056B5',
                                paddingBottom: '19px',
                            }}
                        >
                            {intl.get('terminal.qrPopup.card_title')}
                        </div>
                    </div>) : null}

                    <div
                        className={`${base} payment-card`}
                        style={{
                            width: this.showCardOption() ? '33%' : '50%',
                            border: '3px solid #D3A604',
                            borderRadius: '8px',
                        }}
                        onClick={() => this.handlePaymentOption('counter')}
                    >
                        <div style={{ padding: '16px', height: '100%' }}>
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src="https://image.popcontent.link/cash.jpg"
                            />
                        </div>

                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: '24px',
                                color: '#D3A604',
                                paddingBottom: '19px',
                            }}
                        >
                            {intl.get('terminal.qrPopup.counter_pay_title')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
